import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HeroSection from "../../components/HeroSection";
import FundedByEUSection from "../../components/FundedByEUSection";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

// Admission details
const WorkshopsSection = () => (
  <Section>
    <div className="flex flex-col items-center md:items-stretch md:flex-row md:justify-center">
      <div className="flex flex-col justify-center text-center md:text-left md:mr-3 lg:mr-6">
        <p className="text-xl uppercase lg:text-2xl">Welcome to the</p>
        <p className="text-3xl font-bold uppercase md:mr-0 lg:text-4xl">
          Swedish Job Market
        </p>
      </div>
      <div className="hidden mr-3 border-l-2 border-black border-solid md:block lg:mr-6"></div>
      <div className="flex flex-col justify-center mt-4 text-center md:text-left lg:text-lg md:mt-0">
        <p className="w-64">
          Our workshop series to help expats find a job in Sweden
        </p>
      </div>
    </div>
    <div className="text-center std-mt font-bold uppercase text-2xl lg:text-3xl">
      <p>Step 1:</p>
      <p>Watch Workshops series on youtube</p>
    </div>
    <div className="std-mt">
      <p className="text-lg font-bold md:text-xl">
        Workshop 1:
      </p>
      <p className="mt-2 md:text-lg">
        During the first session of our program for international talents in
        Sweden, we cover the essentials of writing a CV and a cover letter.
        These documents are a central point of any application. Let's discuss
        how to structure them, and what to include.
      </p>
      <iframe
        width="560"
        src="https://www.youtube.com/embed/PV_nxw7jYXo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mx-auto mt-4 max-w-full aspect-video"
      >
      </iframe>
    </div>
    <div className="std-mt">
      <p className="text-lg font-bold md:text-xl">
        Workshop 2:
      </p>
      <p className="mt-2 md:text-lg">
        During the second session of our program for international talents in
        Sweden, we cover best practices for interviews in Sweden, including
        role-playing exercises. The main facilitator is Noopur Thatte, Senior
        Tech Recruiter at Volvo Cars.
      </p>
      <iframe
        width="560"
        src="https://www.youtube.com/embed/DkNUJgZBmAE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mx-auto mt-4 max-w-full aspect-video"
      >
      </iframe>
    </div>
    <div className="std-mt">
      <p className="text-lg font-bold md:text-xl">
        Workshop 3:
      </p>
      <p className="mt-2 md:text-lg">
        During the third session of our program for international talents in
        Sweden, we cover:
      </p>
      <ul className="pl-2 mt-2 list-disc list-inside md:text-lg">
        <li>How to stand out while searching for a job?</li>
        <li className="mt-2">How to fit in the Swedish workplace? What to expect?</li>
        <li className="mt-2">Should you learn Swedish?</li>
      </ul>
      <iframe
        width="560"
        src="https://www.youtube.com/embed/lj-o4fL1Nf4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mx-auto mt-4 max-w-full aspect-video"
      >
      </iframe>
    </div>
    <div className="std-mt">
      <p className="text-lg font-bold md:text-xl">
        Workshop 4:
      </p>
      <p className="mt-2 md:text-lg">
        During the fourth session of our program for international talents in
        Sweden, we discuss the importance of keeping a positive and healthy
        mental balance when applying for jobs.
      </p>
      <p className="mt-4 md:text-lg">
        Let's find clarity, joy and resilience with Laughter Yoga and Emotional
        Freedom Techniques. We will touch on:
      </p>
      <ul className="pl-2 mt-2 list-disc list-inside md:text-lg">
        <li>Why is it so hard to think and act positively?</li>
        <li className="mt-2">How Laughter Yoga can help you re-energize and shift to a more positive mindset?</li>
        <li className="mt-2">How you can let go of negative emotions and be more resourceful with Emotional Freedom Techniques?</li>
      </ul>
      <iframe
        width="560"
        src="https://www.youtube.com/embed/bqcdolUd1fs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="mx-auto mt-4 max-w-full aspect-video"
      >
      </iframe>
    </div>
  </Section>
);

// Graduation requirements
const contributors = [
  {
    name: "Abhishek Bala",
    linkedIn: "https://www.linkedin.com/in/abhishekbala/" 
  },
  {
    name: "Anastasiia Tsarenko",
    linkedIn: "https://www.linkedin.com/in/anastasiia-tsarenko/" 
  },
  {
    name: "Dziyana Katselkina",
    linkedIn: "https://www.linkedin.com/in/dziyanakatselkina/" 
  },
  {
    name: "Irina A. Widmer",
    linkedIn: "https://www.linkedin.com/in/irinawidmer/" 
  },
  {
    name: "Juliana Marulanda",
    linkedIn: "https://www.linkedin.com/in/marulandajuliana/" 
  },
  {
    name: "Kristyna Wagner",
    linkedIn: "https://www.linkedin.com/in/kristyna-wagner/" 
  },
  {
    name: "Laurence Herpelinck ",
    linkedIn: "https://www.linkedin.com/in/laurence-herpelinck-a8602726/" 
  },
  {
    name: "Mikael Säwström",
    linkedIn: "https://www.linkedin.com/in/mikael-s%C3%A4wstr%C3%B6m-196b5085/" 
  },
  {
    name: "Noopur Thatte",
    linkedIn: "https://www.linkedin.com/in/noopurthatte/" 
  },
  {
    name: "Oleksii Shevtsov",
    linkedIn: "https://www.linkedin.com/in/oleksii-shevtsov/" 
  },
  {
    name: "Pritama Sarkar ",
    linkedIn: "https://www.linkedin.com/in/pritama-sarkar-6a592483/" 
  },
  {
    name: "Sinisha Stojchevski",
    linkedIn: "https://www.linkedin.com/in/sinisha-stojchevski-071447167/" 
  },
  {
    name: "Valentina Fermanelli",
    linkedIn: "https://www.linkedin.com/in/valentina-fermanelli/" 
  },
  {
    name: "Yao Zhou",
    linkedIn: "https://www.linkedin.com/in/yao-zhou-78b157182/" 
  },
];

const ConnectWithCommunitySection = () => (
  <Section className="bg-gray-200 md:text-lg">
    <div className="text-center font-bold uppercase text-2xl lg:text-3xl">
      <p>Step 2:</p>
      <p>Connect with Our Community & Contributors</p>
    </div>
    <p className="text-lg std-mt font-bold md:text-xl">
      Our community:
    </p>
    <p className="mt-4">
      On LinkedIn{" "}
      <a
        className="text-orange-700 underline hover:text-blue-700"
        href="https://www.linkedin.com/company/perform-on-top/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Perfom On Top
      </a>{" "}
      page for following updates and networking events.
    </p>
    <p className="mt-2">
      On Facebook{" "}
      <a
        className="text-orange-700 underline hover:text-blue-700"
        href="https://www.facebook.com/groups/173099860774220/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Gothenburg Career Networking Group
      </a>{" "}
      for peer support & communication.
    </p>
    <p className="text-lg mt-8 font-bold md:text-xl">
      Project contributors:
    </p>
    {
      contributors.map((contributor, idx) => (
        <a
          className={`block ${idx === 0 ? "mt-4" : "mt-2"} text-orange-700 underline hover:text-blue-700`}
          key={`contributor-${idx}`}
          href={contributor.linkedIn}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contributor.name}
        </a>
      ))
    }
  </Section>
);

// Program page
const ProgramPage = ({ data }) => {
  return (
    <>
      <HeroSection
        bgImg={data.heroBgImg}
        bgImgAltTxt="Hero background fish church Gothenburg"
        title="Welcome to Sweden"
        subtitle="Start your professional integration journey here"
        shaderClassName="opacity-30 sm:hidden"
      />
      <main>
        <WorkshopsSection />
        <ConnectWithCommunitySection />
        <FundedByEUSection />
      </main>
    </>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const welcomeToSwedenQuery = graphql`
  query WelcomeToSwedenQuery {
    heroBgImg: file(relativePath: { eq: "welcome-to-sweden-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default ProgramPage;
